<template>
    <el-dialog title="Firme" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal="false" >
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
        <el-row :gutter="15" >
            
            <el-col :md='8'>
                <el-form-item label='Nume' >
                    <el-input  class='full-width' v-model='selectedObject.Nume' clearable />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Platitor TVA' >
                    <el-checkbox v-model='selectedObject.PlatitorTVA' true-label='1' false-label='0'> Platitor TVA </el-checkbox>
                </el-form-item>
            </el-col>
        </el-row>
        
    </el-form>
    <span slot="footer" class="dialog-footer" >
        <el-button @click="showPopup=false"     > Renunta  </el-button>
        <el-button type="primary" @click="save" > Salveaza </el-button>
    </span>
</el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';


export default {
    name: "Firme_dialog",
    extends: BasePage,
    data () {
        return {
            baseUrl :'',
            showPopup: false,
            mode: 'add',
            selectedObject: { 
                Nume: '', PlatitorTVA: '1'
            },
            Info: {
            },
            rules: {
                Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
            }
        }
    },
    methods: {
        show_me: async function( id ) {
            this.showPopup        = true;
            if( id == null )
            {
                this.mode = "add";
                this.selectedObject.Nume        = '';
            }
            else
            {
                this.mode             = "edit";
                var            result = await this.post("firme/get_info_item_dialog", { id: id } );
                this.selectedObject   = result.Item;
                
            }
        },
        async get_info(){
            
        },

        save: async function() {
            this.$refs['my-form'].validate( async(valid) => {
                if (valid)
                {
                    await this.post("firme/save", { mode: this.mode, object: this.selectedObject } );
                    this.showPopup = false;
                    this.$emit("save");
                }
            });
        }
    },
    mounted: function() {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>
.full-width {
    width: 100%;
}

</style>